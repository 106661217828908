<template>
  <div class="hc-search home-border">
    <div class="type" style="width: 145px" @mousemove="mousemOve" @mouseleave="mouseLeave">
      <div>
        {{ seachTypeData }}<span class="iconfont icon-xiangxiajiantou"></span>
      </div>
      <ul v-if="ulShow" @mouseleave="mouseLeave" class="select">
        <li @click="changeSeachType('图片')">图片</li>
        <li @click="changeSeachType('视频')">视频</li>
      </ul>
    </div>
    <div class="search-layout">
      <div class="search img l imgT">
        <img class="search l" @click.stop="goSearch"  stats-mark="搜索点击" src="@/assets/image/search/search.png" alt="" />
        <img class="search l hover" @click.stop="goSearch" src="@/assets/image/search/search-000.png" alt="" />
      </div>
      <input type="text" @keyup.enter="goSearch" v-model="keyword" stats-mark="搜索点击" placeholder="开启您的创意之旅…" />
    </div>

    <upload-image-dialog v-if="uploadPic" @close="uploadPic = false"></upload-image-dialog>
  </div>
</template>

<script>
import UploadImageDialog from "@/components/uploadImage";

export default {
  components: { UploadImageDialog },
  data () {
    return {
      uploadPic: false,
      keyword: "",
      seachTypeData: "图片",
      seachType: [
        { id: 1, value: "图片" },
        { id: 2, value: "音乐" },
      ],
      ulShow: false,
    };
  },
  created () { },
  methods: {
    mouseLeave () {
      this.ulShow = false;
    },
    mousemOve () {
      this.ulShow = true;
    },
    changeSeachType (data) {
      this.ulShow = false;
      this.seachTypeData = data;
    },
    goSearch () {
      if (this.seachTypeData == "图片") {
        this.$router.push({
          name: "SearchPage",
          query: {
            keyword: this.keyword
          },
        });
      } else if (this.seachTypeData == "视频") {
        this.$router.push({
          name: "VideoSearchPage",
          query: {
            keyword: this.keyword,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hc-search {
  @include wh(690px, 60px);
  position: absolute;
  top: 240px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  // opacity: 0.9;
  padding: 0 21px 0 0;
  box-sizing: border-box;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333;
  transition: 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 1);
  }

  .type {
    float: left;
    padding: 20px 0 0;
    cursor: pointer;

    div {
      padding-left: 27px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #0a051b;
      line-height: 20px;
      border-right: 1px solid #767676;

      // margin-right: 16px;
      span {
        margin-left: 18px;
        margin-right: 21px;
        font-size: 14px;
      }
    }

    /* &:hover {
      ul {
        display: block;
      }
    } */
    ul {
      //display: none;
      width: 110px;
      margin-top: 20px;
      /* height: 140px; */
      padding: 10px 0;
      box-sizing: border-box;
      text-align: center;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      transition: 0.3s;

      li {
        height: 40px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0a051b;
        line-height: 40px;
        cursor: pointer;
        position: relative;
        text-align: center;

        .new_icon {
          position: absolute;
          right: 25px;
          top: 10px;
          width: 16px;
        }

        &:hover,
        &.active {
          width: 94px;
          height: 40px;
          background: #edf2f5;
          border-radius: 8px;
          margin: 0 auto;

          .new_icon {
            right: 17px;
          }
        }
      }
    }
  }

  input {
    // @include wh(74%, 100%);
    /* @include wh(72%, 100%); */
    width: 100%;
    font-size: 14px;
    background: none;
    float: left;

    &::placeholder {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #767676;
    }
  }

  .img {
    margin-top: 20px;

    cursor: pointer;

    img {
      @include wh(20px, 20px);
    }

    &:hover {
      img {
        display: none;
      }

      img.hover {
        display: block;
      }
    }

    .hover {
      display: none;
    }

    &.search {
      margin-right: 8px;
      margin-left: 16px;
    }

    &.photo {
      position: relative;
    }
  }
}

@media screen and (max-width: 1620px) {
  .hc-search {
    @include wh(520px, 44px);
    top: 173px;

    input {
      // width: 68%;
      /* width: 63%; */
      width: 100%;
    }

    .img {
      margin-top: 0px !important;
    }

    .type {
      padding-top: 12px;

      ul {
        margin-top: 12px;
      }
    }
  }
}

.select {
  border: 1px solid #e9e9eb;
}

.search-layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .imgT {
    margin-top: 0px !important;
  }
}
</style>
