<template>
  <div class="template home-border home-template">
    <!--热门图集-->
    <div class="temp-list">
      <!--标题-->
      <div class="temp-title clear">
        <h2>热门推荐</h2>
      </div>
      <!--模板-->
      <div class="hot-recom">
        <ul class="clear">
          <li v-for="item in recommendations" :key="item.id" stats-mark="热门推荐">
            <router-link :to="
              '/hotmore/' +
              item.id +
              '?name=' +
              item.name
            " target="_blank">
              <div class="recom-box">
                <div class="top">
                  <img :src="item.image[0]" alt="" />
                </div>
                <div class="bott">
                  <div class="img">
                    <img :src="item.image[1]" alt="" />
                  </div>
                  <div class="img">
                    <img :src="item.image[2]" alt="" />
                  </div>
                  <div class="img">
                    <img :src="item.image[3]" alt="" />
                  </div>
                </div>
                <div class="mask">
                  <p>共{{ item.image_num }}张<span>图片</span></p>
                </div>
              </div>
            </router-link>
            <p>{{ item.name }}</p>
          </li>
        </ul>
      </div>
    </div>
    <!--图片素材-->
    <div class="temp-list sort" id="img">
      <!--标题-->
      <div class="temp-title clear">
        <h2>版权图片</h2>

        <router-link :to="`/search`" target="_blank">
          查看更多<span class="iconfont icon-Unfold1"></span>
        </router-link>
      </div>
      <!--模板-->
      <div class="img-material">
        <ul class="clear">
          <li v-for="item in imgData" :key="item.id" stats-mark="图片推荐">
            <div class="img-box">
              <img :src="item.small_preview" alt="" @click="toImgDetails(item)" />
              <div class="image-item-shadowbox">
                <div class="cardiconbox iconbox-down" @click.stop="downdemoFn(item)">
                  样图下载
                </div>
                <el-tooltip content="收藏" placement="top" popper-class="card-tooltip">
                  <div class="cardiconbox iconbox-collect" v-if="!item.is_collect" @click.stop="addCollect(item, 1)">
                    <img class="init" src="@/assets/image/common/imagecollect.png" alt="" />
                  </div>
                </el-tooltip>
                <el-tooltip content="取消收藏" placement="top" popper-class="card-tooltip">
                  <div class="cardiconbox iconbox-collect" v-if="item.is_collect" @click.stop="delCollect(item, 1)">
                    <img class="init" src="@/assets/image/common/imagecollectcheck.png" />
                  </div>
                </el-tooltip>
              </div>
              <div class="image-item-shadowbox-title">
                {{ item.name }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="temp-list sort" id="video">
      <!--标题-->
      <div class="temp-title clear">
        <h2>版权视频</h2>

        <router-link :to="`/video`" target="_blank">
          查看更多<span class="iconfont icon-Unfold1"></span>
        </router-link>
      </div>
      <!--模板-->
      <div class="video-material">
        <ul class="clear">
          <li v-for="item in videoData" :key="item.id" stats-mark="视频推荐">
            <div class="video-box" @mouseenter="productEnter(item.id)" @mouseleave="productLeave(item.id)">
              <video :ref="`video${item.id}`" :id="`video${item.id}`" class="product-video" muted="muted" preload="none"
                :poster="item.preview">
                <source :src="item.small_preview" type="video/mp4" />
              </video>
              <span class="time">{{ item.duration | formatTime }}</span>
              <div class="conten-box" @click.stop="toVideoDetails(item)" @mouseenter="productEnter(item.id)"
                @mouseleave="productLeave(item.id)"></div>
              <div class="image-item-shadowbox">
                <el-tooltip content="收藏" placement="top" popper-class="card-tooltip" v-if="!item.is_collect">
                  <div class="cardiconbox iconbox-collect" @click.stop="addCollect(item, 2)">
                    <img class="init" src="@/assets/image/common/imagecollect.png" alt="" />
                  </div>
                </el-tooltip>
                <el-tooltip content="取消收藏" placement="top" popper-class="card-tooltip" v-if="item.is_collect">
                  <div class="cardiconbox iconbox-collect" @click.stop="delCollect(item, 2)">
                    <img class="init" src="@/assets/image/common/imagecollectcheck.png" />
                  </div>
                </el-tooltip>
              </div>
              <div class="image-item-shadowbox-title">
                {{ item.name }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!--服务保障-->
    <div class="temp-server-box">
      <!--标题-->
      <div class="temp-title clear">
        <h2 class="l">服务保障</h2>
      </div>
      <!-- 服务保障 -->
      <div class="temp-server">
        <ul>
          <li :key="index" v-for="(child, index) in serverList">
            <div class="img l">
              <img :src="child.img" alt="" :style="'width:' + child.width" />
            </div>
            <p class="title">{{ child.title }}</p>
            <p class="info" v-html="child.info"></p>
          </li>
        </ul>
      </div>
    </div>

    <collect-dialog v-if="collect.visible" :moveIds="collect.moveIds" :moveFold="false" :category="collect.category"
      @close="closeCollect" />
  </div>
</template>

<script>
import { Home, } from "@/config/axios/api";
import CollectDialog from "@/components/collectDialog";
export default {
  components: {
    CollectDialog,
  },
  data () {
    return {
      topics: {
        active: 0,
        navNum: 8,
        type: "",
        navList: [],
        list: [],
      },
      serverList: [
        {
          title: "正版授权",
          img: require("@/assets/image/shouquan.png"),
          width: "29px",
          info: "商用无忧",
        },
        {
          title: "海量素材",
          img: require("@/assets/image/sucai.png"),
          width: "32px",
          info: "提供多快好省的独家版权素材",
        },
        {
          title: "授权证书",
          img: require("@/assets/image/zhengshu.png"),
          width: "29px",
          info: "提供正规版权授权书",
        },
        {
          title: "申请发票",
          img: require("@/assets/image/fapiao.png"),
          width: "27px",
          info: "支持在线申请发票方便快捷",
        },
      ],
      swiperOption: {
        spaceBetween: 28, // 两屏之间的间隔
        slidesPerView: "auto",
        slidesPerColumn: 1,
        initialSlide: 0,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      actSoll: "",
      back: "",
      recommendations: [], //热门推荐
      videoData: [], //视频数据
      imgData: [], //图片数据
      designData: [], //设计数据
      exemptData: [], //免抠数据
      pptData: [], //ppt数据
      sortData: [], //排序顺序
      collect: {
        category: 1,
        visible: false,
        moveIds: "",
      },
      downdemo: {
        visible: false,
      },
      sort: [],
    };
  },
  created () {
    this.getList();
  },
  mounted () {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 300);
  },
  destroyed () {
  },
  methods: {
    toVideoDetails (data) {
      window.open(`/details?id=${data.editor_id}&video=true`, "_blank");
    },
    toImgDetails (data) {
      window.open(`/details?id=${data.editor_id}&img=true`, "_blank");
    },
    // 屏幕宽度低于1620 ，展示个数改变
    resizeBody () {
      if (document.querySelector(".home-template").offsetWidth == 1200) {
        this.topics.navNum = 6;
      } else {
        this.topics.navNum = 8;
      }
    },
    getList () {
      // 热门推荐、图片推荐、视频推荐
      Home.topRecommendations().then((res) => {
        if (res.status == 1) {
          this.recommendations = res.data.hot || [];  //热门推荐
          this.videoData = res.data.video || []; //视频数据
          this.imgData = res.data.image || []; //图片数据
        }
      });
    },
    // 视频鼠标移入播放
    productEnter (data) {
      if (document.getElementById("video" + data)) {
        let video = document.getElementById("video" + data);
        video.currentTime = 0;
        let playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              video.addEventListener("timeupdate", () => {
                if (parseInt(video.currentTime) >= video.duration) {
                  video.currentTime = 0;
                  video.pause();
                }
              });
            })
            .catch(() => { });
        }
      }
    },
    // 视频鼠标移出暂停
    productLeave (data) {
      if (document.getElementById("video" + data)) {
        let video = document.getElementById("video" + data);
        video.load();
      }
    },
    // 添加收藏
    addCollect (item, category) {
      if (!this.staticFunc.isLogin()) {
        this.$store.commit("watchShowLogin", true);
        return;
      }
      this.$route.meta.index = 0;
      this.collect.moveIds = item.editor_id;
      this.collect.category = category;
      this.collect.visible = true;
      return false;
    },
    // 取消收藏
    delCollect (item, category) {
      if (category == 1) {
        this.$bus.$emit('delCollect', item);
      } else {
        this.$bus.$emit('delVideoCollect', item);
      }
    },
    // 收藏成功
    closeCollect (refresh, editor_id) {
      if (refresh) {
        if (this.collect.category == 1) {
          for (let i = 0; i < this.imgData.length; i++) {
            if (this.imgData[i].editor_id == editor_id) {
              this.imgData[i].is_collect = true;
            }
          }
        } else {
          for (let i = 0; i < this.videoData.length; i++) {
            if (this.videoData[i].editor_id == editor_id) {
              this.videoData[i].is_collect = true;
            }
          }
        }
      }
      this.collect.visible = false;
    },
    // 样图下载
    downdemoFn (item) {
      this.$bus.$emit('downloaddemo', item, 'demo')
    },
  },
  filters: {
    formatTime (time) {
      // 格式化毫秒，返回String型分秒对象
      // 有可能没获取到，为NaN
      if (!time) return "00:00";
      let min;
      let sec;
      min = Math.floor(time / 60)
        .toString()
        .padStart(2, "0");
      sec = Math.floor(time % 60)
        .toString()
        .padStart(2, "0");
      return min + ":" + sec;
    },
  },
};
</script>

<style lang="scss" scoped>
#exempt {
  ul {
    li {
      background: #fff;
      border: 1px solid #f4f4f4;
    }
  }
}

.back-top {
  position: fixed;
  bottom: 170px;
  right: 8px;
  z-index: 9;
  width: 38px;
  height: 275px;

  .conts {
    width: 38px;
    /* height: 232px; */
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px #d2d2d2;
    border-radius: 4px;

    .contact {
      width: 38px;
      height: 58px;
      background: #ffffff;
      /* @include flex(center,center); */
      position: relative;
      text-align: center;
      line-height: 38px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #3b3946;
      border-radius: 4px;

      .img {
        display: block;
        margin: 0 auto;
        padding-top: 14px;
        width: 16px;
      }

      .img2 {
        display: none;
      }

      .span {
        display: block;
        margin: 0 auto;
        line-height: 18px;
      }

      .info {
        display: none;
        position: absolute;
        right: 54px;
        top: -7px;
        width: 208px;
        height: 58px;
        background: #ffffff;
        box-shadow: 0px 0px 12px 0px rgba(201, 201, 201, 0.5);
        padding: 0 24px 0 17px;
        box-sizing: border-box;
        text-align: center;
        border-radius: 4px;

        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
          line-height: 58px;

          span {
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
          }
        }

        .avator-icon {
          position: absolute;
          top: 18px;
          right: -21px;
          width: 0;
          height: 0;
          border-bottom: 12px solid transparent;
          border-right: 12px solid transparent;
          border-left: 12px solid #fff;
          border-top: 12px solid transparent;
        }
      }
    }

    .kehu {
      cursor: pointer;

      &:hover {
        .info {
          display: block;
        }
      }
    }

    .active {
      background: $activeColor;
      color: #fff;

      .span {
        color: #fff;
      }

      .img1 {
        display: none;
      }

      .img2 {
        display: block;
      }
    }

    .cups {
      cursor: pointer;

      &:hover {
        background: $activeColor;
        color: #fff;

        .span {
          color: #fff;
        }

        .img1 {
          display: none;
        }

        .img2 {
          display: block;
        }
      }
    }
  }

  .to-top {
    display: block;
    width: 38px;
    height: 38px;
    background: #ffffff;
    @include flex(center, center);
    margin-top: 6px;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0px 0px 12px 0px #d2d2d2;

    .iconfont {
      transform: rotate(-90deg);
      font-size: 24px;
      margin: 6px;
    }
  }
}

.home-template {
  @include wh(1817px, auto);
  margin: 0 auto;

  .temp-list {
    margin-top: 61px;
    margin-bottom: 24px;

    .temp-title {
      margin-bottom: 37px;

      h2 {
        font-size: 32px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #222222;
        line-height: 34px;
        float: left;
        margin-right: 40px;

        .icon-xiangxiajiantou {
          font-size: 20px;
        }
      }

      .alist-box {
        float: left;

        span {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
          line-height: 34px;
          padding: 0 20px;
          background: #edf2f5;
          border-radius: 17px;
          float: left;
          margin-right: 20px;
          cursor: pointer;

          &:hover {
            background: #e6edf1;
          }
        }
      }

      a {
        float: right;
        font-size: 16px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #222222;
        line-height: 34px;
        margin-right: 4px;

        .iconfont {
          font-size: 16px;
        }
      }
    }

    .hot-recom {
      ul li {
        width: 341px;
        height: 373px;
        margin-right: 28px;
        float: left;

        .recom-box {
          width: 341px;
          height: 341px;
          margin-bottom: 16px;
          background: #efefef;
          border-radius: 12px;
          overflow: hidden;
          position: relative;
          cursor: pointer;

          .top {
            width: 100%;
            height: 227px;
            margin-bottom: 3px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              display: block;
              object-fit: cover;
            }

            img.pptimg {
              object-fit: unset;
              height: auto;
            }
          }

          .bott {
            width: 100%;
            height: 111px;

            .img {
              float: left;
              width: 111px;
              height: 111px;
              margin-right: 3px;

              img {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
              }

              &:nth-of-type(3n) {
                margin-right: 0;
              }
            }
          }

          .mask {
            display: none;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: rgba(0, 0, 0, 0.4);

            p {
              line-height: 341px;
              text-align: center;
              font-size: 18px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #ffffff;
            }
          }

          &:hover {
            .mask {
              display: block;
            }
          }
        }

        p {
          font-size: 18px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #222222;
          line-height: 27px;
        }

        &:nth-of-type(5n) {
          margin-right: 0;
        }
      }
    }

    .video-material {
      ul li {
        width: 342px;
        height: 192px;
        margin-right: 26px;
        margin-bottom: 26px;
        float: left;

        .video-box {
          width: 100%;
          height: 100%;
          background: #d8d8d8;
          border-radius: 12px;
          overflow: hidden;
          position: relative;

          .conten-box {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }

          .product-video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .time {
            position: absolute;
            bottom: 12px;
            right: 12px;
            width: 43px;
            height: 20px;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            line-height: 20px;
            text-align: center;
            font-size: 12px;
            font-family: Helvetica;
            color: #ffffff;
          }

          &:hover {
            .image-item-shadowbox {
              display: block;
            }

            .image-item-shadowbox-title {
              display: block;
            }
          }
        }

        &:nth-of-type(5n) {
          margin-right: 0;
        }
      }
    }

    .img-material {
      ul li {
        width: 342px;
        height: 257px;
        margin-right: 26px;
        margin-bottom: 26px;
        float: left;

        .img-box {
          cursor: pointer;
          width: 100%;
          height: 100%;
          background: #d8d8d8;
          border-radius: 12px;
          overflow: hidden;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
          }

          &:hover {
            .image-item-shadowbox {
              display: block;
            }

            .image-item-shadowbox-title {
              display: block;
            }
          }
        }

        &:nth-of-type(5n) {
          margin-right: 0;
        }
      }
    }

    .plane-material {
      ul li {
        width: 341px;
        height: 341px;
        background: #fff;
        border-radius: 12px;
        margin-right: 28px;
        margin-bottom: 28px;
        float: left;
        position: relative;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #f4f4f4;

        &:nth-of-type(5n) {
          margin-right: 0;
        }
      }
    }

    .ppt-material {
      ul li {
        width: 341px;
        height: 477px;
        /* background: #f4f4f4; */
        border-radius: 12px;
        margin-right: 19px;
        margin-bottom: 19px;
        float: left;
        position: relative;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;

        &:nth-of-type(5n) {
          margin-right: 0;
        }
      }
    }

    .image-item-shadowbox {
      display: none;
      height: 44px;
      position: absolute;
      top: 0;
      right: 0;

      .cardiconbox {
        position: absolute;
        bottom: 12px;
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 2px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
      }

      .iconbox-down {
        right: 50px;
        width: 66px;
        top: 16px;
      }

      .iconbox-search {
        right: 50px;
        top: 16px;
      }

      .iconbox-collect {
        right: 12px;
        top: 16px;
        background: none;

        img {
          width: 100%;
          height: 100%;
          padding: 0;
          background: none;
        }
      }
    }
  }

  // 专题推荐
  .temp-zt-box {
    margin-top: 61px;
    margin-bottom: 24px;

    .temp-title {
      margin-bottom: 37px;

      h2 {
        font-size: 28px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #222222;
        line-height: 34px;
        float: left;
        margin-right: 40px;
      }

      .alist-box {
        float: left;

        span {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
          line-height: 34px;
          padding: 0 20px;
          background: #edf2f5;
          border-radius: 17px;
          float: left;
          margin-right: 20px;
          cursor: pointer;
        }
      }

      a {
        float: right;
        font-size: 16px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #222222;
        line-height: 34px;
        margin-right: 4px;

        .iconfont {
          font-size: 16px;
        }
      }
    }

    .temp-zt {
      width: 100%;
      height: 63px;
      margin-bottom: 27px;
      position: relative;

      .swiper-container {
        width: 100%;
        height: 100%;

        .swiper-wrapper {
          .swiper-slide {
            margin-top: 3px;
            width: 177px;
            height: 59px;
            cursor: pointer;
            border-radius: 8px;
            overflow: hidden;

            .el-image,
            img {
              @include wh(100%, 100%);
            }

            img {
              object-fit: cover;
            }

            .info {
              position: absolute;
              width: 100%;
              height: 59px;
              background: rgba(0, 0, 0, 0.4);
              bottom: 0;
              left: 0;
              text-align: center;
              line-height: 59px;
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
            }

            &:hover {
              transform: translateY(-2px);
            }
          }
        }
      }

      .swiper-button-next,
      .swiper-container-rtl .swiper-button-prev {
        width: 32px;
        height: 32px;
        background: #ffffff;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
        overflow: hidden;
        border-radius: 50%;
        text-align: center;
        line-height: 32px;
        right: -16px;
        margin-top: -16px;
      }

      .swiper-button-prev,
      .swiper-container-rtl .swiper-button-next {
        width: 32px;
        height: 32px;
        background: #ffffff;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
        overflow: hidden;
        border-radius: 50%;
        text-align: center;
        line-height: 32px;
        left: -16px;
        margin-top: -16px;

        .iconfont {
          transform: rotate(180deg);
          display: inline-block;
        }
      }

      .swiper-button-prev.swiper-button-disabled,
      .swiper-button-next.swiper-button-disabled {
        display: none;
      }
    }

    .temp-list {
      .cont-box {
        overflow: hidden;

        ul {
          li {
            width: 285px;
            height: 214px;
            float: left;
            background: #d8d8d8;
            border-radius: 12px;
            overflow: hidden;
            margin-right: 21px;
            margin-bottom: 21px;
            position: relative;

            .el-image,
            img {
              @include wh(100%, 100%);
            }

            img {
              object-fit: cover;
            }

            &:nth-of-type(6n) {
              margin-right: 0;
            }

            &:hover {
              .image-item-shadowbox {
                display: block;
              }

              .image-item-shadowbox-title {
                display: block;
              }
            }
          }
        }
      }
    }

    .image-item-shadowbox {
      display: none;
      height: 44px;
      position: absolute;
      top: 0;
      right: 0;

      .cardiconbox {
        position: absolute;
        bottom: 12px;
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 2px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
      }

      .iconbox-down {
        right: 88px;
        width: 66px;
      }

      .iconbox-collect {
        right: 12px;
        background: none;

        img {
          width: 100%;
          height: 100%;
          padding: 0;
          background: none;
        }
      }
    }
  }

  .temp-server-box {
    margin-top: 61px;
    margin-bottom: 120px;

    .temp-title {
      margin-bottom: 37px;

      h2 {
        font-size: 28px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #222222;
        line-height: 34px;
        float: left;
        margin-right: 40px;
      }

      .alist-box {
        float: left;

        span {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
          line-height: 34px;
          padding: 0 20px;
          background: #edf2f5;
          border-radius: 17px;
          float: left;
          margin-right: 20px;
          cursor: pointer;
        }
      }

      a {
        float: right;
        font-size: 16px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #222222;
        line-height: 34px;
        margin-right: 4px;

        .iconfont {
          font-size: 16px;
        }
      }
    }

    .temp-server {
      overflow: hidden;

      ul {
        width: 100%;
        @include flex(center, space-between);

        li {
          @include wh(438px, 180px);
          background: linear-gradient(180deg, #eff9fa 0%, #ffffff 100%);
          border-radius: 12px;
          border: 1px solid #deecf6;
          padding: 61px 44px 0 41px;
          text-align: right;

          .img {
            width: 58px;
            height: 58px;
            background: linear-gradient(90deg, #6f7eff 0%, #5691ff 100%);
            border-radius: 50%;
            @include flex(center, center);
          }

          p.title {
            margin-top: 2px;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #222222;
            line-height: 28px;
          }

          p.info {
            margin-top: 6px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 20px;
          }
        }
      }

      .ul-a {
        width: 100%;
        display: block;

        li {
          overflow: hidden;
          margin-right: 60px;
          margin-bottom: 20px;
          float: left;
          width: auto;
          height: auto;
          padding: 0;
          background: none;
          border: none;
          text-align: left;

          a {
            display: block;
            padding: 0 5px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #666;
            line-height: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1910px) {
  .home-template {
    @include wh(1297px, auto);
    margin: 0 auto;

    .temp-list {
      margin-top: 61px;
      margin-bottom: 10px;

      .temp-title {
        margin-bottom: 37px;

        h2 {
          font-size: 28px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #222222;
          line-height: 34px;
          float: left;
          margin-right: 40px;
        }

        .alist-box {
          float: left;

          span {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 34px;
            padding: 0 20px;
            background: #edf2f5;
            border-radius: 17px;
            float: left;
            margin-right: 20px;
            cursor: pointer;
          }
        }

        a {
          float: right;
          font-size: 16px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #222222;
          line-height: 34px;
          margin-right: 4px;

          .iconfont {
            font-size: 16px;
          }
        }
      }

      .hot-recom {
        ul li {
          width: 243px;
          height: 275px;
          margin-right: 20px;
          float: left;

          .recom-box {
            width: 243px;
            height: 243px;
            margin-bottom: 16px;
            background: #efefef;
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            cursor: pointer;

            .top {
              width: 100%;
              height: 160px;
              margin-bottom: 3px;

              img {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
              }
            }

            .bott {
              width: 100%;
              height: 81px;

              .img {
                float: left;
                width: 79px;
                height: 81px;
                margin-right: 3px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                  object-fit: cover;
                }

                &:nth-of-type(3n) {
                  margin-right: 0;
                }
              }
            }

            .mask {
              display: none;
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              background: rgba(0, 0, 0, 0.4);

              p {
                line-height: 243px;
                text-align: center;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ffffff;
              }
            }

            &:hover {
              .mask {
                display: block;
              }
            }
          }

          p {
            font-size: 16px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #222222;
            line-height: 24px;
          }

          &:nth-of-type(5n) {
            margin-right: 0;
          }
        }
      }

      .video-material {
        ul li {
          width: 244px;
          height: 136px;
          margin-right: 19px;
          margin-bottom: 19px;
          float: left;

          .video-box {
            width: 100%;
            height: 100%;
            background: #d8d8d8;
            border-radius: 8px;
            overflow: hidden;
            position: relative;

            .product-video {
              width: 100%;
              height: 100%;
            }

            .time {
              position: absolute;
              bottom: 12px;
              right: 12px;
              width: 43px;
              height: 20px;
              background: rgba(0, 0, 0, 0.3);
              border-radius: 10px;
              line-height: 20px;
              text-align: center;
              font-size: 12px;
              font-family: Helvetica;
              color: #ffffff;
            }
          }

          &:nth-of-type(5n) {
            margin-right: 0;
          }
        }
      }

      .img-material {
        ul li {
          width: 244px;
          height: 182px;
          margin-right: 19px;
          margin-bottom: 19px;
          float: left;

          .img-box {
            cursor: pointer;
            width: 100%;
            height: 100%;
            background: #d8d8d8;
            border-radius: 8px;
            overflow: hidden;
            position: relative;

            img {
              width: 100%;
              height: 100%;
              display: block;
              object-fit: cover;
            }
          }

          &:nth-of-type(5n) {
            margin-right: 0;
          }
        }
      }

      .plane-material {
        ul li {
          width: 244px;
          height: 244px;
          background: #f4f4f4;
          border-radius: 10px;
          margin-right: 19px;
          margin-bottom: 19px;
          float: left;
          position: relative;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;

          &:nth-of-type(5n) {
            margin-right: 0;
          }
        }
      }

      .ppt-material {
        ul li {
          width: 244px;
          height: 334px;
          /* background: #f4f4f4; */
          border-radius: 12px;
          margin-right: 19px;
          margin-bottom: 19px;
          float: left;
          position: relative;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;

          &:nth-of-type(5n) {
            margin-right: 0;
          }
        }
      }
    }

    // 专题推荐
    .temp-zt-box {
      margin-top: 61px;
      margin-bottom: 10px;

      .temp-title {
        margin-bottom: 37px;

        h2 {
          font-size: 28px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #222222;
          line-height: 34px;
          float: left;
          margin-right: 40px;
        }

        .alist-box {
          float: left;

          span {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 34px;
            padding: 0 20px;
            background: #edf2f5;
            border-radius: 17px;
            float: left;
            margin-right: 20px;
            cursor: pointer;
          }
        }

        a {
          float: right;
          font-size: 16px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #222222;
          line-height: 34px;
          margin-right: 4px;

          .iconfont {
            font-size: 16px;
          }
        }
      }

      .temp-zt {
        width: 100%;
        height: 63px;
        margin-bottom: 27px;
        position: relative;

        .swiper-container {
          width: 100%;
          height: 100%;

          .swiper-wrapper {
            .swiper-slide {
              margin-top: 3px;
              width: 177px;
              height: 59px;
              cursor: pointer;
              border-radius: 8px;
              overflow: hidden;

              .el-image,
              img {
                @include wh(100%, 100%);
              }

              img {
                object-fit: cover;
              }

              .info {
                position: absolute;
                width: 100%;
                height: 59px;
                background: rgba(0, 0, 0, 0.4);
                bottom: 0;
                left: 0;
                text-align: center;
                line-height: 59px;
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
              }

              &:hover {
                transform: translateY(-2px);
              }
            }
          }
        }

        .swiper-button-next,
        .swiper-container-rtl .swiper-button-prev {
          width: 32px;
          height: 32px;
          background: #ffffff;
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
          overflow: hidden;
          border-radius: 50%;
          text-align: center;
          line-height: 32px;
          right: -16px;
          margin-top: -16px;
        }

        .swiper-button-prev,
        .swiper-container-rtl .swiper-button-next {
          width: 32px;
          height: 32px;
          background: #ffffff;
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
          overflow: hidden;
          border-radius: 50%;
          text-align: center;
          line-height: 32px;
          left: -16px;
          margin-top: -16px;

          .iconfont {
            transform: rotate(180deg);
            display: inline-block;
          }
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          display: none;
        }
      }

      .temp-list {
        .cont-box {
          overflow: hidden;

          ul {
            li {
              width: 204px;
              height: 153px;
              float: left;
              background: #d8d8d8;
              border-radius: 10px;
              overflow: hidden;
              margin-right: 14px;
              margin-bottom: 14px;

              .el-image,
              img {
                @include wh(100%, 100%);
              }

              img {
                object-fit: cover;
              }

              &:nth-of-type(6n) {
                margin-right: 0;
              }
            }
          }
        }

        /* li {
          @include wh(310px, 232px);
          margin-bottom: 12px;
          margin-right: 12px;
          border-radius: 4px;
          overflow: hidden;
          &:nth-of-type(4n),
          &:nth-of-type(3n) {
            margin-right: 10px;
          }
          &:nth-of-type(5n) {
            margin-right: 0;
          }
          &:hover {
            transform: none;
            // transform: translateY(-4px) scale(1.04);
          }
        } */
      }
    }

    .temp-server-box {
      margin-top: 61px;
      margin-bottom: 100px;

      .temp-title {
        margin-bottom: 37px;

        h2 {
          font-size: 28px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #222222;
          line-height: 34px;
          float: left;
          margin-right: 40px;
        }

        .alist-box {
          float: left;

          span {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 34px;
            padding: 0 20px;
            background: #edf2f5;
            border-radius: 17px;
            float: left;
            margin-right: 20px;
            cursor: pointer;
          }
        }

        a {
          float: right;
          font-size: 16px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #222222;
          line-height: 34px;
          margin-right: 4px;

          .iconfont {
            font-size: 16px;
          }
        }
      }

      .temp-server {
        ul {
          width: 100%;
          @include flex(center, space-between);

          li {
            @include wh(314px, 160px);
            background: linear-gradient(180deg, #eff9fa 0%, #ffffff 100%);
            border-radius: 12px;
            border: 1px solid #deecf6;
            padding: 51px 24px 0 22px;
            text-align: right;

            .img {
              width: 58px;
              height: 58px;
              background: linear-gradient(90deg, #6f7eff 0%, #5691ff 100%);
              border-radius: 50%;
              @include flex(center, center);
            }

            p.title {
              margin-top: 2px;
              font-size: 20px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #222222;
              line-height: 28px;
            }

            p.info {
              margin-top: 6px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #222222;
              line-height: 20px;
            }
          }
        }
      }
    }
  }

  .image-item-shadowbox-title {
    text-align: left;
    line-height: 44px;
    color: white;
    display: none;
    height: 44px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 44px;
    background: linear-gradient(180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.56) 100%);
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 70px;
    padding-left: 10px;
    white-space: nowrap;
    pointer-events: none;
  }
}

.recommend {
  float: right;
  font-size: 16px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #222222;
  line-height: 34px;
  margin-right: 4px;
  cursor: pointer;
}
</style>
