<template>
  <div id="index" >
    <div class="home-content content">
      <div class="hc-top clear">
        <banner />
        <search />
      </div>
      <div class="home-hot clear">
        <hot />
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Banner from "./bannerTemplate";
import Search from "./searchTemplate";
import Hot from "./hotTemplate";
import Footer from "@/components/foot.vue";
import { mapState, mapMutations } from "vuex";
import staticFunc from "@/config/static/func";
export default {
  metaInfo: {
    title: "365版权图库，专注原创商用设计图片视频下载，会员免费设计素材模板独家图库",
    meta: [
      {
        name: "keywords",
        content:
          "365编辑器,365,摄影,高清图片,图库,背景图片,风景图片,视频素材,视频模板,短视频,商用图片,正版授权,设计素材,编辑器素材,设计模板",
      },
      {
        name: "description",
        content:
          "365编辑器是旗下的一款实用的微信图文排版和内容编辑器的在线工具，为内容创作者提供丰富模板素材、版权图片、图片编辑、视频制作、一键排版等系列服务，助力提高运营效率。同时产品无缝内嵌全行业内容发布管理系统，帮助企业用户打造精美排版保障数据安全，开启专业运营，助力企业增效降本。",
      },
    ],
  },
  name: "Index",
  components: { Banner, Search, Hot, Footer },
  data () {
    return {};
  },
  created () {
    this.showActivity();
  },
  computed: {
    ...mapState(["pageScroll"]),
  },
  mounted () {
    window.addEventListener("scroll", this.pageScrollFn);
    this.Statistics.defalutEvent({
      statsMark: "首页曝光",
      projectPage: '61',
      projectArea: "137",
      eventArea: '137',
      eventType: '1',
      eventId: '1274',
      eventName: 'index_exposure',
    })
  },
  destroyed () {
    window.removeEventListener("scroll", this.pageScrollFn);
  },
  methods: {
    ...mapMutations(["watchPageScroll"]),
    showActivity () {
      let cooke = staticFunc.getCookie("activity");
      let cooke2 = staticFunc.getCookie("xuanf");
      if (this.$store.getters.loginInfo) {
        if (cooke) {
          this.$store.commit("watchShowActivity", false);
        } else {
          this.$store.commit("watchShowActivity", true);
          this.$store.commit("watchShowXuanf", false);
        }
        if (cooke2) {
          this.$store.commit("watchShowXuanf", false);
        }
      } else {
        this.$store.commit("watchShowActivity", true);
        this.$store.commit("watchShowXuanf", false);
      }
    },
    pageScrollFn () {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.watchPageScroll(!!scrollTop);
    },
  },
};
</script>
<style scoped lang="scss">
.home-content {
  margin: 0 auto;
  width: 100%;
  padding-top: 60px;

  .hc-top {
    position: relative;
    @include wh(100%, 520px);
  }
}

@media screen and (max-width: 1620px) {
  .home-content {
    .hc-top {
      @include wh(100%, 390px);
    }
  }
}
</style>
