<template>
  <!-- 收藏 -->
  <div class="collect banquan-dialog">
    <div class="collect-inner">
      <div class="title">{{ moveFold ? "选择文件夹" : "添加至文件夹" }}</div>
      <ul class="collect-list">
        <li v-for="item in dataList" :key="item.id" :class="
          selectDirId == item.id
            ? 'active'
            : oldDirId == item.id
            ? 'noclick'
            : ''
        " @click="oldDirId == item.id ? '' : selectItem(item.id, item.name)">
          <p>{{ item.name }}</p>
          <img src="@/assets/image/common/check.png" alt="" />
        </li>
      </ul>
      <div class="collect-input" v-if="showInput">
        <input type="text" placeholder="新建文件夹名称" maxlength="10" v-model="folderName" />
        <div :class="errorMsg == '' ? '' : 'red'">{{ errorMsg }}</div>
      </div>
      <div class="collect-input" v-else></div>
      <div class="collect-btn">
        <div @click="addFolder">新建文件夹</div>
        <div class="active" @click="submitBtn">确定</div>
      </div>

      <div class="banquan-close" @click="$emit('close')">
        <span class="iconfont icon-close"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { GlobalApi } from "@/config/axios/api";
import { mapState } from "vuex";

export default {
  name: "collect",
  /**
   * moveFold	 是否移动收藏夹
   * moveIds  移动的素材id
   * imgId  移动的素材id
   */
  props: [
    "moveFold",
    "moveIds",
    "license_scope",
    "teamMaterial",
    "oldDirId",//旧的收藏夹id
    "category",// 1图片  2 视频
  ],
  data () {
    return {
      showInput: false,
      errorMsg: "",
      folderName: "",
      pageApi: {
        getList: "", // 获取列表
        add: "", //添加收藏夹
        move: "", // 移动素材
      },
      dataList: [],
      selectDirId: "",
      selectDirName: "",
      loadStatus: false,
    };
  },

  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.pageApi = {
      getList: GlobalApi.getDirectoryList,
      add: GlobalApi.addDirectory,
      move: GlobalApi.moveCollection,
      addCollect: this.category == 1 ? GlobalApi.addCollection : GlobalApi.addVideosCollection,
    }
    this.getList();
  },
  mounted () { },
  destroyed () { },
  methods: {
    getList () {
      this.pageApi.getList({ category: this.category }).then((res) => {
        if (res.status == 1) {
          this.dataList = res.data;
          if (this.dataList.length == 0) {
            this.showInput = true;
          }
        }
      });
    },
    selectItem (id, name) {
      this.selectDirId = id;
      this.selectDirName = name;
    },
    addFolder () {
      this.showInput = !this.showInput;
    },
    submitBtn () {
      if (this.loadStatus) return;
      this.loadStatus = true;
      // 如果有输入框， 点击确定 是添加文件夹
      if (this.showInput) {
        this.pageApi.add({ name: this.folderName, category: this.category }).then((res) => {
          this.loadStatus = false;
          if (res.status == 1) {
            this.$message.success({ message: "新建文件夹成功" });
            this.getList();
            this.folderName = "";
            this.errorMsg = "";
            this.showInput = false;
          } else {
            this.errorMsg = res.msg ? res.msg : "新建文件夹失败";
          }
        });
      } else {
        if (!this.selectDirId) {
          this.$message.warning("请选择文件夹");
          this.loadStatus = false;
          return;
        }
        // 是否是移动
        if (this.moveFold) {
          let condition = { id: this.selectDirId, editor_id: this.moveIds };
          this.pageApi.move(condition).then((res) => {
            this.loadStatus = false;
            if (res.status == 1) {
              this.$message.success({
                message: "素材已成功添加至文件夹" + this.selectDirName,
              });
              this.$emit("close", true);
            }
          });
        } else {
          let condition = { dir_id: this.selectDirId, id: this.moveIds };
          this.pageApi.addCollect(condition).then((res) => {
            this.loadStatus = false;
            if (res.status == 1) {
              this.$message.success({
                message: "素材已成功添加至收藏夹" + this.selectDirName,
              });
              this.$emit("close", true, this.moveIds);
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.collect-inner {
  width: 400px;
  // height: 420px;
  background: #ffffff;
  border-radius: 8px;
  padding: 36px 33px 38px;
  box-sizing: border-box;
  position: relative;
  cursor: auto;

  .title {
    text-align: center;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2b2a24;
    line-height: 30px;
    margin-bottom: 22px;
  }

  .collect-list {
    max-height: 218px;
    overflow-y: auto;
    @include scroll-bar();

    li {
      @include flex(center, space-between);
      width: 100%;
      height: 44px;
      padding: 0 17px 0 16px;
      background: #f6f7f9;
      border-radius: 4px;
      border: 1px solid #e2e2e2;
      margin-bottom: 14px;
      cursor: pointer;

      img {
        display: none;
        width: 18px;
        height: 18px;
      }

      &.active {
        background: #ffffff;

        img {
          display: inline-block;
        }
      }

      &.noclick {
        cursor: no-drop;
        opacity: 0.5;
        background: #ffffff;
        border-radius: 8px;

        img {
          display: inline-block;
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      p {
        @include text-ellipsis();
      }
    }
  }

  .collect-input {
    margin-top: 28px;

    input {
      outline: none;
      width: 100%;
      border: none;
      // line-height: 46px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2b2a24;
      line-height: 20px;
      padding-bottom: 8px;
    }

    div {
      border-top: 1px solid #222222;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 17px;
      padding-top: 3px;

      &.red {
        border-top: 1px solid #ff0000;
        color: #ff0000;
      }
    }
  }

  .collect-btn {
    margin-top: 32px;
    padding: 0 10px;
    @include flex(center, space-between);

    div {
      width: 144px;
      height: 40px;
      text-align: center;
      border-radius: 4px;
      border: 1px solid #222222;
      line-height: 38px;
      cursor: pointer;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1f1f1f;

      &.active {
        background: #222222;
        color: #ffffff;
      }
    }
  }
}
</style>
