
<template>
  <div class="hc-banner home-border">
    <el-carousel :interval="3000" :arrow="bannerList.length === 1 ? 'never' : 'always'" ref="carousel"
      @click.native="gotoTemp()" @change="changeCarousel" trigger="click"
      :indicator-position="bannerList.length === 2 ? 'none' : 'outside'">
      <el-carousel-item v-for="(item, index) in bannerList" :key="index"
        :style="{ 'background-size': 'cover', 'background-image': `url(${item.preview})` }">
      </el-carousel-item>
      <div v-if="bannerList.length === 2">
        <el-carousel-item class="carouselItem" v-for="(item, index) in bannerList" :key="index"
          :style="{ 'background-image': `url(${item.preview})` }">
        </el-carousel-item>
      </div>
    </el-carousel>
    <!--当只有两项时显示自定义的指示器-->
    <ul class="
        el-carousel__indicators
        el-carousel__indicators--horizontal
        el-carousel__indicators--outside
      " v-if="bannerList.length === 2">
      <li :class="index === indexActive ? 'is-active' : ''"
        class="el-carousel__indicator el-carousel__indicator--horizontal" v-for="(group, index) in bannerList"
        :key="index" @click="handleCarousel(index)">
        <!-- //点击指示器，切换幻灯片的索引 -->
        <button class="el-carousel__button"></button>
      </li>
    </ul>
  </div>
</template>

<script>
import { Home } from "@/config/axios/api";

export default {
  data () {
    return {
      bannerList: [],
      indexActive: 0,
    };
  },
  created () {
    this.getBanner();
  },
  methods: {
    changeCarousel (a) {
      if (this.bannerList.length === 2) {
        a === 2
          ? (this.indexActive = 0)
          : a === 3
            ? (this.indexActive = 1)
            : (this.indexActive = a);
      } else {
        this.indexActive = a;
      }
    },
    handleCarousel (index) {
      this.$refs.carousel.setActiveItem(index);
    },
    async getBanner () {
      Home.getBannerList({}).then((res) => {
        if (Array.isArray(res.data)) {
          let data = [...res.data];
          this.bannerList = data;
        }
      });
    },

    gotoTemp () {
      let item = this.bannerList[this.indexActive];
      if (!item) return;
      if (item.url) {
        window.open(item.url);
      } else if (item.relation) {
        window.open(item.relation);
      }
    },
  },
};
</script>

<style scoped>
.recommendPage .swiper-container {
  position: relative;
  width: 100%;
  height: 200px;
  background: pink;
}

.recommendPage .swiper-container .swiper-slide {
  width: 100%;
  line-height: 200px;
  background: yellowgreen;
  color: #000;
  font-size: 16px;
  text-align: center;
}
</style>
<style lang="scss">
.hc-banner {
  @include wh(100%, 520px);
  float: left;
  cursor: pointer;
  position: relative;
  background-size: 100% 100%;

  .el-carousel__container {
    height: 520px;
    overflow: hidden;

    .el-carousel__arrow {
      width: 70px;
      height: 70px;
      font-size: 30px;
      font-weight: 600;
    }
  }

  .el-carousel__indicators.el-carousel__indicators--horizontal {
    // width: 76px;
  }

  .el-carousel__indicators {
    position: absolute !important;
    bottom: 3px;
    width: 100%;
    height: 29px;
    left: 0;
  }
}

.el-carousel__item {
  background-size: 100% 100%;
  background-position: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

@media screen and (max-width:1620px) {
  .hc-banner {
    @include wh(100%, 390px);

    .el-carousel__container {
      height: 390px;
    }
  }
}
</style>
